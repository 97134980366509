import dayjs from 'dayjs'
import {
  AmazonOrderStatus,
  GetDashboardPerformanceOverviewQuery,
  Marketplace,
} from '../../../../generated/graphql'
import { calculateTotalAmount } from '../../../../helpers/calculateTotalAmount'
import { convertToEuro } from '../../../../helpers/convertToEuro'

export type DatedPerformanceOverview = {
  date: string
  orders: ColoredAmazonOrder[]
  totalShipped: number
  totalPending: number
  totalCanceled: number
  totalRevenue: number
  pendingRevenue: number
  performance: {
    products: ProductPerformanceDetails[]
    marketplaces: MarketplacePerformanceDetails[]
  }
}

export type ColoredAmazonOrder = {
  productName: string
  internalSku: string
  recommendedRetailPrice: number
  color: string
  asin: string
  sku: string
  marketplace: Marketplace
  quantity: number
  price: {
    amount: number
    currency: string
  }
  status: AmazonOrderStatus
  shippingAddress: {
    latitude: number | null
    longitude: number | null
  }
  purchaseDate: string
}

export type PerformanceDetails = {
  totalShipped: number
  totalPending: number
  totalCanceled: number
  totalRevenue: number
  pendingRevenue: number
}

export type ProductPerformanceDetails = PerformanceDetails & {
  productName: string
  internalSku: string
  amazonSku: string
  color: string
}

export type MarketplacePerformanceDetails = PerformanceDetails & {
  marketplace: Marketplace
}

export function preparePerformanceOverview(
  data: GetDashboardPerformanceOverviewQuery | undefined,
  marketplaces?: Marketplace[],
  orderStatus?: AmazonOrderStatus[],
  fulfillmentChannels?: string[]
): DatedPerformanceOverview[] {
  if (!data) {
    return []
  }

  const orders: ColoredAmazonOrder[] = data.products.flatMap((product) => {
    const productVariants = product.productVariants ?? []

    const internalSku = productVariants.find(
      (productVariant) => productVariant.internalSku
    )?.internalSku

    const amazonProducts =
      productVariants.flatMap((productVariant) => {
        return productVariant.amazonProducts ?? []
      }) ?? []

    return amazonProducts.flatMap((amazonProduct) => {
      if (!amazonProduct.amazonListings) {
        return []
      }

      return amazonProduct.amazonListings.flatMap((amazonListing) => {
        const marketplace = amazonListing.marketplace.name

        return amazonListing.orderLineItems.flatMap((item) => {
          const order = item.amazonOrder

          if (marketplaces && !marketplaces.includes(marketplace)) {
            return []
          }

          if (orderStatus && !orderStatus.includes(order.status)) {
            return []
          }

          if (fulfillmentChannels && !fulfillmentChannels.includes(order.fulfillmentChannel)) {
            return []
          }

          return {
            productName: product.name,
            internalSku: internalSku ?? amazonListing.sku,
            recommendedRetailPrice: amazonListing.recommendedRetailPrice ?? 0,
            color: product.color ?? '#000000',
            asin: amazonProduct.asin,
            sku: amazonListing.sku,
            marketplace: amazonListing.marketplace.name,
            quantity: item.quantity,
            price: {
              amount: +(item.itemPrice.amount ?? 0),
              currency: item.itemPrice.currency ?? 'EUR',
            },
            status: order.status,
            shippingAddress: {
              latitude: order.shippingAddress.latitude ?? null,
              longitude: order.shippingAddress.longitude ?? null,
            },
            purchaseDate: order.purchaseDate,
          }
        })
      })
    })
  })

  const dailyOrders = orders.reduce<{ date: string; orders: ColoredAmazonOrder[] }[]>(
    (previousValue, currentValue) => {
      const date = currentValue?.purchaseDate?.split('T')[0] ?? ''

      const existingDate = previousValue.find((item) => item.date === date)

      if (existingDate) {
        existingDate.orders.push(currentValue)
      } else {
        previousValue.push({
          date,
          orders: [currentValue],
        })
      }

      return previousValue
    },
    []
  )

  const performanceOverview = dailyOrders.map((item) => {
    const { totalShipped, totalPending, totalCanceled, totalRevenue, pendingRevenue } =
      item.orders.reduce<PerformanceDetails>(
        (previousValue, currentValue) => {
          if (currentValue.status === AmazonOrderStatus.SHIPPED) {
            previousValue.totalShipped += currentValue.quantity
            previousValue.totalRevenue += currentValue.price.amount ?? 0
          } else if (
            [AmazonOrderStatus.UNSHIPPED, AmazonOrderStatus.PENDING].includes(currentValue.status)
          ) {
            previousValue.totalPending += currentValue.quantity
            previousValue.pendingRevenue += convertToEuro(
              calculateTotalAmount(currentValue.recommendedRetailPrice, currentValue.quantity),
              currentValue.marketplace
            )
          } else if (currentValue.status === AmazonOrderStatus.CANCELED) {
            previousValue.totalCanceled++
          }

          return previousValue
        },
        {
          totalShipped: 0,
          totalPending: 0,
          totalCanceled: 0,
          totalRevenue: 0,
          pendingRevenue: 0,
        }
      )

    const productPerformanceOverview = item.orders.reduce<ProductPerformanceDetails[]>(
      (previousValue, currentValue) => {
        const existingSku = previousValue.find((item) => item.amazonSku === currentValue.sku)

        if (existingSku) {
          if (currentValue.status === AmazonOrderStatus.SHIPPED) {
            existingSku.totalShipped += currentValue.quantity
            existingSku.totalRevenue += currentValue.price.amount ?? 0
          } else if (
            [AmazonOrderStatus.UNSHIPPED, AmazonOrderStatus.PENDING].includes(currentValue.status)
          ) {
            existingSku.totalPending += currentValue.quantity
            existingSku.pendingRevenue += convertToEuro(
              calculateTotalAmount(currentValue.recommendedRetailPrice, currentValue.quantity),
              currentValue.marketplace
            )
          } else if (currentValue.status === AmazonOrderStatus.CANCELED) {
            existingSku.totalCanceled += currentValue.quantity
          }
        } else {
          previousValue.push({
            productName: currentValue.productName,
            internalSku: currentValue.internalSku,
            amazonSku: currentValue.sku,
            color: currentValue.color,
            totalShipped:
              currentValue.status === AmazonOrderStatus.SHIPPED ? currentValue.quantity : 0,
            totalPending: [AmazonOrderStatus.UNSHIPPED, AmazonOrderStatus.PENDING].includes(
              currentValue.status
            )
              ? currentValue.quantity
              : 0,
            totalCanceled:
              currentValue.status === AmazonOrderStatus.CANCELED ? currentValue.quantity : 0,
            totalRevenue:
              currentValue.status === AmazonOrderStatus.SHIPPED
                ? (currentValue.price.amount ?? 0)
                : 0,
            pendingRevenue: [AmazonOrderStatus.UNSHIPPED, AmazonOrderStatus.PENDING].includes(
              currentValue.status
            )
              ? convertToEuro(
                  calculateTotalAmount(currentValue.recommendedRetailPrice, currentValue.quantity),
                  currentValue.marketplace
                )
              : 0,
          })
        }

        return previousValue
      },
      []
    )

    const marketplacePerformanceOverview = item.orders.reduce<MarketplacePerformanceDetails[]>(
      (previousValue, currentValue) => {
        const existingMarketplace = previousValue.find(
          (item) => item.marketplace === currentValue.marketplace
        )

        if (existingMarketplace) {
          if (currentValue.status === AmazonOrderStatus.SHIPPED) {
            existingMarketplace.totalShipped += currentValue.quantity
            existingMarketplace.totalRevenue += currentValue.price.amount ?? 0
          } else if (
            [AmazonOrderStatus.UNSHIPPED, AmazonOrderStatus.PENDING].includes(currentValue.status)
          ) {
            existingMarketplace.totalPending += currentValue.quantity
            existingMarketplace.pendingRevenue += convertToEuro(
              calculateTotalAmount(currentValue.recommendedRetailPrice, currentValue.quantity),
              currentValue.marketplace
            )
          } else if (currentValue.status === AmazonOrderStatus.CANCELED) {
            existingMarketplace.totalCanceled += currentValue.quantity
          }
        } else {
          previousValue.push({
            marketplace: currentValue.marketplace,
            totalShipped:
              currentValue.status === AmazonOrderStatus.SHIPPED ? currentValue.quantity : 0,
            totalPending: [AmazonOrderStatus.UNSHIPPED, AmazonOrderStatus.PENDING].includes(
              currentValue.status
            )
              ? currentValue.quantity
              : 0,
            totalCanceled:
              currentValue.status === AmazonOrderStatus.CANCELED ? currentValue.quantity : 0,
            totalRevenue:
              currentValue.status === AmazonOrderStatus.SHIPPED
                ? (currentValue.price.amount ?? 0)
                : 0,
            pendingRevenue: [AmazonOrderStatus.UNSHIPPED, AmazonOrderStatus.PENDING].includes(
              currentValue.status
            )
              ? convertToEuro(
                  calculateTotalAmount(currentValue.recommendedRetailPrice, currentValue.quantity),
                  currentValue.marketplace
                )
              : 0,
          })
        }

        return previousValue
      },
      []
    )

    const sortedProductPerformanceOverview = productPerformanceOverview.sort(
      (a, b) => b.totalRevenue - a.totalRevenue
    )
    const sortedMarketplacePerformanceOverview = marketplacePerformanceOverview.sort(
      (a, b) => b.totalRevenue - a.totalRevenue
    )

    return {
      date: item.date,
      orders: item.orders,
      totalShipped,
      totalPending,
      totalCanceled,
      totalRevenue,
      pendingRevenue,
      performance: {
        products: sortedProductPerformanceOverview,
        marketplaces: sortedMarketplacePerformanceOverview,
      },
    }
  })

  return performanceOverview.sort((a, b) => {
    return dayjs(b.date).diff(dayjs(a.date))
  })
}
