import { useQuery } from '@apollo/client'
import { AgGridReact } from 'ag-grid-react'
import { DatePicker, Space } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ViewContainer } from '../../../components/Layout/ContentWrapper'
import { StatusResult } from '../../../components/Layout/StatusResult/StatusResult'
import { SubHeader } from '../../../components/Layout/SubHeader/SubHeader'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import {
  GetServiceInvoiceLineItemsDocument,
  GetServiceInvoiceLineItemsQuery,
} from '../../../generated/graphql'
import { useDateRangePresets } from '../../../hooks/useDateRangePresets'
import { useGlobalStore } from '../../../stores/useGlobalStore'
import { InvoiceLineItemsTable } from './InvoiceLineItemsTable'
import { CreateInvoiceModal } from './components/CreateInvoiceModal'

const { RangePicker } = DatePicker

const defaultDateRange: [Dayjs, Dayjs] = [dayjs().startOf('year'), dayjs().endOf('year')]

export const InvoiceLineItemsView = () => {
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!
  const { t } = useTranslation(['invoices'])
  const gridRef = useRef<AgGridReact<InvoiceLineItemRecord>>(null)
  const [selectedRows, setSelectedRows] = useState<InvoiceLineItemRecord[]>([])
  const [dateRange, setDateRange] = useState(defaultDateRange)

  const presets = useDateRangePresets()

  const { error, data, loading, refetch } = useQuery(GetServiceInvoiceLineItemsDocument, {
    skip: !selectedCompany.uuid,
    variables: {
      companyUuid: selectedCompany.uuid,
      from: dateRange[0].format('YYYY-MM-DD'),
      to: dateRange[1].format('YYYY-MM-DD'),
    },
    fetchPolicy: 'cache-first',
  })

  const handleSuccess = async () => {
    gridRef.current?.api?.deselectAll()
    await refetch()
  }

  const handleSelectionChanged = useCallback(() => {
    const selectedRows = gridRef.current?.api?.getSelectedRows() ?? []
    setSelectedRows(selectedRows)
  }, [])

  if (loading) return <LoadingSpinner />
  if (error) {
    return (
      <StatusResult
        status="500"
        title="500"
        subTitle={t('shared.error.message', { ns: 'translation' })}
      />
    )
  }

  const lineItems = prepareLineItems(data)

  return (
    <>
      <SubHeader
        heading={t('invoices:lineItems.heading')}
        rightContent={
          <Space>
            <RangePicker
              value={dateRange}
              onCalendarChange={(values) => {
                if (values?.[0] && values?.[1]) {
                  setDateRange([values[0].startOf('day'), values[1].endOf('day')])
                }
              }}
              allowClear={false}
              disabledDate={(date) => date.isAfter(dayjs())}
              format="DD.MM.YYYY"
              placement="bottomRight"
              presets={presets}
            />
            <CreateInvoiceModal gridRef={gridRef} onSuccess={handleSuccess} />
          </Space>
        }
      />
      <ViewContainer>
        <InvoiceLineItemsTable
          ref={gridRef}
          lineItems={lineItems}
          onSaveSuccess={handleSuccess}
          onSelectionChanged={handleSelectionChanged}
        />
      </ViewContainer>
    </>
  )
}

export type InvoiceLineItemRecord = {
  invoiceUuid: string | null
  uuid: string
  title: string
  article: string
  sellerId: string
  netAmount: number
  currency: string
  commissionInPercent: number
  quantity: number
  taxRate: number
  bookingNumber: number
  paymentStatus: string
  issueDate: string
  performancePeriodStartDate: string
  performancePeriodEndDate: string
  comment: string | null
  createdAt: string
  updatedAt: string
}

const prepareLineItems = (
  data: GetServiceInvoiceLineItemsQuery | undefined
): InvoiceLineItemRecord[] => {
  if (!data) {
    return []
  }

  return data.serviceInvoiceLineItems.map((item) => ({
    uuid: item.uuid,
    invoiceUuid: item.invoice?.uuid ?? '',
    title: item.title,
    article: item.invoiceArticle?.name ?? '',
    sellerId: item.buyerCompany.sellerId,
    netAmount: item.netAmount,
    currency: item.currency,
    commissionInPercent: item.commissionInPercent,
    quantity: item.quantity,
    taxRate: item.taxRate,
    bookingNumber: item.bookingNumber,
    paymentStatus: item.paymentStatus,
    issueDate: item.issueDate,
    performancePeriodStartDate: item.performancePeriodStartDate,
    performancePeriodEndDate: item.performancePeriodEndDate,
    comment: item.comment ?? null,
    createdAt: item.createdAt,
    updatedAt: item.updatedAt,
  }))
}
