import { useMutation, useQuery } from '@apollo/client'
import {
  CellValueChangedEvent,
  ColDef,
  ColGroupDef,
  EditableCallbackParams,
  GridApi,
  ICellRendererParams,
} from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { Badge, Button, Flex, Popconfirm, Space } from 'antd'
import dayjs from 'dayjs'
import { Eye, Pen, Plus, Save, Trash } from 'lucide-react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import { FormattedDate } from '../../../components/FormattedDate'
import { Table } from '../../../components/Table/Table'
import {
  CreateInvoiceArticleDocument,
  Currency,
  GetInvoiceArticleCategoriesDocument,
  GetInvoiceArticlesDocument,
  UpdateInvoiceArticleDocument,
} from '../../../generated/graphql'
import { formatMoney } from '../../../helpers/formatMoney'
import { formatPercentage } from '../../../helpers/formatPercentage'
import { dateComparator, dateFilterParams, findInsertionIndex } from '../../../helpers/tableHelpers'
import { useGlobalStore } from '../../../stores/useGlobalStore'
import { TablePersistKey } from '../../../stores/useTableStore'
import {
  InvoiceArticleDiscountRecord,
  QuantityDiscountsTable,
} from './components/QuantityDiscountsTable'

export type InvoiceArticleRecord = {
  uuid: string
  name: string
  description: string
  category: string
  type: string
  netAmount: number
  currency: string
  commissionInPercent: number
  isActive: boolean
  discounts: InvoiceArticleDiscountRecord[]
  createdAt: string
  updatedAt: string
}

type InvoiceArticlesTableProps = {
  invoiceArticles: InvoiceArticleRecord[]
}

const currencyOptions = Object.values(Currency).map((currencyValue) => ({
  value: currencyValue,
  label: currencyValue,
}))

export const InvoiceArticlesTable = ({
  invoiceArticles: initialInvoiceArticles,
}: InvoiceArticlesTableProps) => {
  const { t } = useTranslation(['translation', 'invoices'])
  const [rowData, setRowData] = useState<InvoiceArticleRecord[]>(initialInvoiceArticles)
  const gridRef = useRef<AgGridReact<InvoiceArticleRecord>>(null)
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!

  const { data: categoriesData, loading: categoriesLoading } = useQuery(
    GetInvoiceArticleCategoriesDocument,
    {
      variables: {
        companyUuid: selectedCompany.uuid,
      },
      fetchPolicy: 'cache-first',
    }
  )

  const [updateInvoiceArticle] = useMutation(UpdateInvoiceArticleDocument)
  const [createInvoiceArticle] = useMutation(CreateInvoiceArticleDocument)

  const onCellValueChanged = useCallback(
    async (event: CellValueChangedEvent<InvoiceArticleRecord>) => {
      if (!event) {
        console.error('Cell value changed event is null')
        return
      }

      const field = event.colDef.field as keyof InvoiceArticleRecord

      if (!field) {
        return false
      }

      const newValue = event.newValue
      const oldValue = event.oldValue

      if (newValue === oldValue) {
        return false
      }

      try {
        let value = newValue
        if (typeof newValue === 'string') {
          value = newValue.trim()
        }

        const updatedData = { ...event.data, [field]: value }

        if (field === 'name') {
          const typeValue = String(value).toUpperCase().replace(/\s+/g, '_')
          updatedData.type = typeValue
        }

        if (field === 'currency') {
          event.api.refreshCells({
            force: true,
            rowNodes: [event.node],
            columns: ['netAmount'],
          })
        }

        event.api.applyTransaction({
          update: [updatedData],
        })

        // Skip update mutation for new rows
        if (!event.data.uuid.startsWith('temp-')) {
          await updateInvoiceArticle({
            variables: {
              uuid: event.data.uuid,
              input: {
                [field]: value,
                ...(field === 'name' ? { type: updatedData.type } : {}),
              },
            },
          })

          toast.success(t('invoices:articles.table.update.success'))
        }
      } catch (error) {
        event.api.applyTransaction({
          update: [{ ...event.data, [field]: oldValue }],
        })
        console.error(error)
        toast.error(t('invoices:articles.table.update.error'))
      } finally {
        event.api.refreshCells({
          force: true,
          rowNodes: [event.node],
          columns: [field, ...(field === 'name' ? ['type'] : [])],
        })
      }
    },
    [updateInvoiceArticle, t]
  )

  const handleToggleActive = useCallback(
    async (uuid: string, isActive: boolean) => {
      try {
        await updateInvoiceArticle({
          variables: {
            uuid,
            input: {
              isActive,
            },
          },
          refetchQueries: [GetInvoiceArticlesDocument],
        })

        toast.success(
          isActive
            ? t('invoices:articles.table.restore.success')
            : t('invoices:articles.table.archive.success')
        )
      } catch (error) {
        console.error(error)
        toast.error(t('shared.error.message'))
      }
    },
    [updateInvoiceArticle, t]
  )

  const DiscountDetailRenderer = useCallback(
    (params: ICellRendererParams<InvoiceArticleRecord>) => {
      const discounts = params.data?.discounts ?? []
      const invoiceArticleUuid = params.data?.uuid ?? ''

      return (
        <div style={{ paddingTop: 4, paddingLeft: 40 }}>
          <QuantityDiscountsTable discounts={discounts} invoiceArticleUuid={invoiceArticleUuid} />
        </div>
      )
    },
    []
  )

  const handleAddEmptyRow = useCallback(() => {
    const api = gridRef.current?.api
    if (!api) return

    const categories = categoriesData?.invoiceArticleCategories ?? []
    if (categories.length === 0) {
      toast.error(t('invoices:articles.table.noCategoriesError'))
      return
    }

    const newEmptyRow: InvoiceArticleRecord = {
      uuid: `temp-${Date.now()}`,
      name: '',
      description: '',
      category: categories[0]?.name ?? '',
      type: '',
      netAmount: 0,
      currency: 'EUR',
      commissionInPercent: 0,
      isActive: true,
      discounts: [],
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    }

    const insertIndex = findInsertionIndex(api)
    api.applyTransaction({ add: [newEmptyRow], addIndex: insertIndex })
  }, [categoriesData?.invoiceArticleCategories, categoriesLoading, t])

  const handleSaveNewRows = useCallback(
    async (gridApi: GridApi<InvoiceArticleRecord>) => {
      try {
        const rowData: InvoiceArticleRecord[] = []
        gridApi.forEachNodeAfterFilterAndSort((node) => {
          if (node.data) {
            rowData.push(node.data)
          }
        })

        const newRows = rowData.filter((row) => row.uuid.startsWith('temp-'))

        if (newRows.length === 0) {
          toast.info(t('invoices:articles.table.save.noNewRows'))
          return
        }

        const isValid = newRows.every((row) => row.name && row.netAmount > 0)

        if (!isValid) {
          toast.error(t('invoices:articles.table.save.invalidRows'))
          return
        }

        for (const newRow of newRows) {
          const { data } = await createInvoiceArticle({
            variables: {
              input: {
                name: newRow.name,
                description: newRow.description,
                invoiceArticleCategory: newRow.category,
                type: newRow.type,
                netAmount: newRow.netAmount,
                currency: newRow.currency as Currency,
                commissionInPercent: newRow.commissionInPercent,
              },
            },
          })

          if (data?.createInvoiceArticle) {
            const createdArticle = data.createInvoiceArticle
            gridApi.applyTransaction({
              remove: [newRow],
              add: [
                {
                  ...createdArticle,
                  description: createdArticle.description ?? '',
                  category: createdArticle.invoiceArticleCategory?.name,
                  isActive: true,
                  createdAt: new Date().toISOString(),
                  updatedAt: new Date().toISOString(),
                  discounts: [],
                },
              ],
            })
          }
        }

        toast.success(t('invoices:articles.table.save.success'))
      } catch (error) {
        console.error(error)
        toast.error(t('invoices:articles.table.save.error'))
      }
    },
    [t]
  )

  useEffect(() => {
    setRowData(initialInvoiceArticles)
  }, [initialInvoiceArticles])

  const columnDefs = useMemo<(ColDef<InvoiceArticleRecord> | ColGroupDef<InvoiceArticleRecord>)[]>(
    () => [
      {
        lockPosition: 'left',
        filter: false,
        resizable: false,
        sortable: false,
        suppressColumnsToolPanel: true,
        suppressNavigable: true,
        suppressSizeToFit: true,
        maxWidth: 40,
        minWidth: 40,
        cellRenderer: 'agGroupCellRenderer',
      },
      {
        lockPosition: 'left',
        filter: false,
        resizable: false,
        sortable: false,
        suppressColumnsToolPanel: true,
        suppressNavigable: true,
        suppressSizeToFit: true,
        minWidth: 96,
        maxWidth: 96,
        headerComponent: ActionHeader,
        headerComponentParams: {
          onAddEmptyRow: handleAddEmptyRow,
          onSaveNewRows: () => {
            const api = gridRef.current?.api
            if (api) handleSaveNewRows(api)
          },
          api: gridRef.current?.api,
        },
        cellRenderer: ({ data }: ICellRendererParams<InvoiceArticleRecord>) => {
          const uuid = data?.uuid
          const isActive = data?.isActive
          const isNewRow = uuid?.startsWith('temp-')
          const api = gridRef.current?.api

          if (!uuid) {
            return null
          }

          if (isNewRow) {
            const newRows: InvoiceArticleRecord[] = []
            api?.forEachNode((rowNode) => {
              if (rowNode.data?.uuid?.startsWith('temp-')) {
                newRows.push(rowNode.data)
              }
            })
            newRows.sort((a, b) => {
              const timeA = parseInt(a.uuid?.split('-')[1] ?? '0')
              const timeB = parseInt(b.uuid?.split('-')[1] ?? '0')
              return timeA - timeB
            })
            const index = newRows.findIndex((row) => row.uuid === uuid) + 1

            return (
              <Flex gap={16} align="center" style={{ width: '100%' }}>
                <Button
                  icon={<Trash size={16} />}
                  danger
                  onClick={() => {
                    if (api) {
                      api.applyTransaction({ remove: [data] })
                      api.refreshCells({ force: true })
                    }
                  }}
                />
                <Badge count={index} />
              </Flex>
            )
          }

          return (
            <Space>
              <Popconfirm
                title={t(
                  isActive
                    ? 'invoices:articles.table.archive.title'
                    : 'invoices:articles.table.restore.title'
                )}
                description={t(
                  isActive
                    ? 'invoices:articles.table.archive.description'
                    : 'invoices:articles.table.restore.description'
                )}
                onConfirm={() => handleToggleActive(uuid, !isActive)}
                okText={t(
                  isActive
                    ? 'invoices:articles.table.archive.okText'
                    : 'invoices:articles.table.restore.okText'
                )}
                cancelText={t(
                  isActive
                    ? 'invoices:articles.table.archive.cancelText'
                    : 'invoices:articles.table.restore.cancelText'
                )}
              >
                <Button icon={isActive ? <Trash size={16} /> : <Eye size={16} />} />
              </Popconfirm>
            </Space>
          )
        },
      },
      {
        field: 'name',
        headerName: t('invoices:articles.table.article'),
        headerTooltip: t('invoices:articles.table.articleTooltip'),
        filter: 'agTextColumnFilter',
        editable: true,
        cellRenderer: ({
          value,
        }: ICellRendererParams<InvoiceArticleRecord, InvoiceArticleRecord['name']>) => {
          return (
            <Space>
              <Pen size={16} />
              {value ?? t('shared.button.edit', { ns: 'translation' })}
            </Space>
          )
        },
      },
      {
        field: 'description',
        headerName: t('invoices:articles.table.description'),
        filter: 'agTextColumnFilter',
        editable: true,
        cellRenderer: ({
          value,
        }: ICellRendererParams<InvoiceArticleRecord, InvoiceArticleRecord['description']>) => {
          return (
            <Space>
              <Pen size={16} />
              {value ?? t('shared.button.edit', { ns: 'translation' })}
            </Space>
          )
        },
      },
      {
        field: 'netAmount',
        headerName: t('invoices:articles.table.netPrice'),
        filter: 'agNumberColumnFilter',
        editable: true,
        cellRenderer: ({
          data,
          value,
        }: ICellRendererParams<InvoiceArticleRecord, InvoiceArticleRecord['netAmount']>) => {
          const currency = data?.currency ?? 'EUR'

          return (
            <Space>
              <Pen size={16} />
              {formatMoney(value ?? 0, currency) ?? t('shared.button.edit', { ns: 'translation' })}
            </Space>
          )
        },
      },
      {
        field: 'currency',
        headerName: t('invoices:articles.table.currency'),
        filter: 'agTextColumnFilter',
        editable: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: currencyOptions.map((option) => option.value),
        },
        cellRenderer: ({
          value,
        }: ICellRendererParams<InvoiceArticleRecord, InvoiceArticleRecord['currency']>) => {
          return (
            <Space>
              <Pen size={16} />
              {value ?? t('shared.button.edit', { ns: 'translation' })}
            </Space>
          )
        },
      },
      {
        field: 'category',
        headerName: t('invoices:articles.table.category'),
        headerTooltip: t('invoices:articles.table.categoryTooltip'),
        filter: 'agTextColumnFilter',
        editable: (params: EditableCallbackParams<InvoiceArticleRecord, 'uuid'>) =>
          params.data?.uuid.startsWith('temp-') ?? false,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: categoriesData?.invoiceArticleCategories?.map((cat) => cat.name) ?? [],
        },
        cellRenderer: ({
          value,
          data,
        }: ICellRendererParams<InvoiceArticleRecord, InvoiceArticleRecord['category']>) => {
          const isNewRow = data?.uuid?.startsWith('temp-') ?? false

          return (
            <Space>
              {isNewRow && <Pen size={16} />}
              {value ?? (isNewRow ? t('shared.button.edit', { ns: 'translation' }) : '')}
            </Space>
          )
        },
      },
      {
        field: 'commissionInPercent',
        headerName: t('invoices:articles.table.commissionInPercent'),
        headerTooltip: t('invoices:articles.table.commissionInPercentTooltip'),
        filter: 'agNumberColumnFilter',
        editable: true,
        cellEditorParams: {
          min: 0,
        },
        cellRenderer: ({
          value,
        }: ICellRendererParams<
          InvoiceArticleRecord,
          InvoiceArticleRecord['commissionInPercent']
        >) => {
          const commissionInPercent = (value ?? 0) / 100

          return (
            <Space>
              <Pen size={16} />
              {formatPercentage(commissionInPercent) ??
                t('shared.button.edit', { ns: 'translation' })}
            </Space>
          )
        },
      },
      {
        field: 'createdAt',
        headerName: t('invoices:articles.table.createdAt'),
        filter: 'agDateColumnFilter',
        filterParams: dateFilterParams,
        comparator: dateComparator,
        cellRenderer: ({
          value,
        }: ICellRendererParams<InvoiceArticleRecord, InvoiceArticleRecord['createdAt']>) => {
          if (value) {
            return <FormattedDate date={value} withRelativeTime={true} />
          }
        },
        hide: true,
      },
      {
        field: 'updatedAt',
        headerName: t('invoices:articles.table.updatedAt'),
        filter: 'agDateColumnFilter',
        filterParams: dateFilterParams,
        comparator: dateComparator,
        cellRenderer: ({
          value,
        }: ICellRendererParams<InvoiceArticleRecord, InvoiceArticleRecord['updatedAt']>) => {
          if (value) {
            return <FormattedDate date={value} withRelativeTime={true} />
          }
        },
      },
      {
        field: 'uuid',
        headerName: t('invoices:articles.table.uuid'),
        filter: 'agTextColumnFilter',
        hide: true,
      },
    ],
    [
      t,
      categoriesData?.invoiceArticleCategories,
      handleAddEmptyRow,
      handleSaveNewRows,
      handleToggleActive,
    ]
  )

  return (
    <Table<InvoiceArticleRecord>
      ref={gridRef}
      columnDefs={columnDefs}
      defaultExcelExportParams={{
        fileName: `invoice-articles-${dayjs().format('DD-MM-YYYY')}`,
      }}
      detailCellRenderer={DiscountDetailRenderer}
      fullHeight
      masterDetail
      onCellValueChanged={onCellValueChanged}
      getRowId={(params) => params.data.uuid}
      persist={{
        key: TablePersistKey.INVOICE_ARTICLE,
        storage: localStorage,
      }}
      rowData={rowData}
      showColumnChooser
      showExport
      showFilterReset
      showUniversalSearch
    />
  )
}

const ActionHeader = ({
  onAddEmptyRow,
  onSaveNewRows,
  api,
}: {
  onAddEmptyRow: () => void
  onSaveNewRows: () => void
  api: GridApi<InvoiceArticleRecord> | undefined
}) => {
  const [hasNewRows, setHasNewRows] = useState(false)

  useEffect(() => {
    const checkForNewRows = () => {
      if (api) {
        const rowData: InvoiceArticleRecord[] = []
        api.forEachNode((node) => {
          if (node.data) {
            rowData.push(node.data)
          }
        })
        const newRows = rowData.some((row) => row?.uuid?.startsWith('temp-'))
        setHasNewRows(newRows)
      }
    }

    checkForNewRows()
    const intervalId = setInterval(checkForNewRows, 500)

    return () => clearInterval(intervalId)
  }, [api])

  const handleSaveNewRows = useCallback(() => {
    if (hasNewRows) {
      onSaveNewRows()
    }
  }, [hasNewRows, onSaveNewRows])

  return (
    <Space>
      <Button onClick={onAddEmptyRow} icon={<Plus size={16} />} type="primary" />
      <Button
        onClick={handleSaveNewRows}
        icon={<Save size={16} />}
        type="primary"
        disabled={!hasNewRows}
      />
    </Space>
  )
}
