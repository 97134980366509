import { useMutation, useQuery } from '@apollo/client'
import { useTheme } from '@emotion/react'
import {
  CellValueChangedEvent,
  ColDef,
  GetRowIdParams,
  ICellRendererParams,
  IRowNode,
  RowSelectionOptions,
  SelectionChangedEvent,
  SelectionColumnDef,
} from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { Button, Flex, Space, Tag } from 'antd'
import { Storage } from 'aws-amplify'
import dayjs from 'dayjs'
import { Eye, Pen } from 'lucide-react'
import { useCallback, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import { FormattedDate } from '../../../components/FormattedDate'
import { Table } from '../../../components/Table/Table'
import {
  GenerateServiceInvoicePdfsDocument,
  GetAllActiveCompaniesDocument,
  ServiceInvoicePaymentStatus,
  ServiceInvoiceType,
  UpdateServiceInvoiceDocument,
} from '../../../generated/graphql'
import { dateComparator, dateFilterParams } from '../../../helpers/tableHelpers'
import { useGlobalStore } from '../../../stores/useGlobalStore'
import { TablePersistKey } from '../../../stores/useTableStore'
import { InvoiceLineItemsSubTable } from './components/InvoiceLineItemsSubTable'

const bucketName = import.meta.env.VITE_AWS_S3_BUCKET_NAME_INVOICES

export type InvoiceLineItemRecord = {
  uuid: string
  title: string
  netAmount: number
  currency: string
  commissionInPercent: number
  quantity: number
  taxRate: number
  bookingNumber: number
  paymentStatus: string
  issueDate: string
  performancePeriodStartDate: string
  performancePeriodEndDate: string
  createdAt: string
  updatedAt: string
}

export type InvoiceRecord = {
  uuid: string
  invoiceNumber: string
  customer: string
  paymentStatus: ServiceInvoicePaymentStatus
  type: ServiceInvoiceType
  issueDate: string
  dueDate: string | null
  sentAt: string | null
  updatedAt: string
  createdAt: string
  bucketKey: string | null
  lineItems: InvoiceLineItemRecord[]
}

type InvoicesTableProps = {
  invoices: InvoiceRecord[]
  onSelectionChange?: (selectedRows: InvoiceRecord[]) => void
}

export const InvoiceTable = ({ invoices, onSelectionChange }: InvoicesTableProps) => {
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!
  const { t } = useTranslation(['invoices', 'translation'])
  const gridRef = useRef<AgGridReact<InvoiceRecord>>(null)

  const { data: companiesData } = useQuery(GetAllActiveCompaniesDocument)

  const [updateInvoice] = useMutation(UpdateServiceInvoiceDocument)
  const [generatePdfs] = useMutation(GenerateServiceInvoicePdfsDocument)

  const customerOptions = useMemo(() => {
    return (
      companiesData?.companies
        ?.filter((company) => company.uuid !== selectedCompany.uuid)
        ?.map((company) => ({
          label: company.sellerId,
          value: company.uuid,
        })) ?? []
    )
  }, [companiesData, selectedCompany.uuid])

  const handleViewPdf = useCallback(
    async (invoice: InvoiceRecord) => {
      try {
        if (invoice.bucketKey) {
          const fileUrl = await Storage.get(invoice.bucketKey, {
            customPrefix: {
              public: '',
            },
            bucket: bucketName,
          })

          window.open(fileUrl, '_blank')
          return
        }

        const { data } = await generatePdfs({
          variables: {
            input: {
              serviceInvoiceUuids: [invoice.uuid],
            },
          },
        })

        const result = data?.generateServiceInvoicePdfs.results[0]
        if (result?.success && result.pdf) {
          const binaryStr = atob(result.pdf)
          const bytes = new Uint8Array(binaryStr.length)
          for (let i = 0; i < binaryStr.length; i++) {
            bytes[i] = binaryStr.charCodeAt(i)
          }
          const blob = new Blob([bytes], { type: 'application/pdf' })
          const url = URL.createObjectURL(blob)
          window.open(url, '_blank')
          URL.revokeObjectURL(url)
        } else {
          toast.error(result?.message || t('invoices:pdf.generation.error'))
        }
      } catch (error) {
        console.error(error)
        toast.error(t('invoices:pdf.generation.error'))
      }
    },
    [generatePdfs, t]
  )

  const columnDefs = useInvoiceColumns(customerOptions)

  const LineItemDetailRenderer = useCallback((params: ICellRendererParams<InvoiceRecord>) => {
    const lineItems = params.data?.lineItems ?? []

    return (
      <div style={{ paddingTop: 4, paddingLeft: 96 }}>
        <InvoiceLineItemsSubTable lineItems={lineItems} />
      </div>
    )
  }, [])

  const onCellValueChanged = useCallback(
    async (event: CellValueChangedEvent<InvoiceRecord>) => {
      if (
        !event.colDef.field ||
        event.newValue === event.oldValue ||
        event.data.uuid.startsWith('temp-')
      )
        return

      try {
        await updateInvoice({
          variables: {
            uuid: event.data.uuid,
            input: {
              paymentStatus: event.newValue,
            },
          },
          update: (cache, { data }) => {
            const updatedInvoice = data?.updateServiceInvoice
            if (updatedInvoice) {
              cache.modify({
                id: cache.identify(updatedInvoice),
                fields: {
                  paymentStatus: () => event.newValue,
                  updatedAt: () => new Date().toISOString(),
                },
              })
            }
          },
        })
        toast.success(t('invoices:table.update.success'))
      } catch (error) {
        console.error(error)
        event.node.setDataValue(event.colDef.field, event.oldValue)
        toast.error(t('invoices:table.update.error'))
      }
    },
    [t, updateInvoice]
  )

  const isRowSelectable = useCallback((params: IRowNode<InvoiceRecord>) => {
    return (
      !params.data?.sentAt && params.data?.paymentStatus !== ServiceInvoicePaymentStatus.CANCELED
    )
  }, [])

  const rowSelection = useMemo<RowSelectionOptions<InvoiceRecord>>(() => {
    return {
      mode: 'multiRow',
      isRowSelectable,
      selectAll: 'filtered',
    }
  }, [isRowSelectable])

  const selectionColumnDef = useMemo<SelectionColumnDef>(() => {
    return {
      minWidth: 86,
      maxWidth: 86,
      pinned: 'left',
      tooltipValueGetter: (params) => {
        if (params.data?.sentAt) {
          return t('invoices:table.selectionTooltip.alreadySent')
        }
        if (params.data?.paymentStatus === ServiceInvoicePaymentStatus.CANCELED) {
          return t('invoices:table.selectionTooltip.canceled')
        }
        return ''
      },
      cellRenderer: (params: ICellRendererParams<InvoiceRecord>) => {
        if (!params.data) return null

        return (
          <Flex align="center">
            <Button
              icon={<Eye size={16} />}
              onClick={(e) => {
                e.stopPropagation()
                if (params.data) handleViewPdf(params.data)
              }}
            />
          </Flex>
        )
      },
    }
  }, [t, handleViewPdf])

  const handleSelectionChanged = useCallback(
    (event: SelectionChangedEvent<InvoiceRecord>) => {
      const selectedRows = event.api.getSelectedRows()
      onSelectionChange?.(selectedRows)
    },
    [onSelectionChange]
  )

  const getRowId = useCallback((params: GetRowIdParams<InvoiceRecord>) => {
    return params.data.uuid
  }, [])

  return (
    <Table<InvoiceRecord>
      ref={gridRef}
      columnDefs={columnDefs}
      defaultExcelExportParams={{
        fileName: `invoices-${dayjs().format('DD-MM-YYYY')}`,
      }}
      detailCellRenderer={LineItemDetailRenderer}
      fullHeight
      masterDetail
      onCellValueChanged={onCellValueChanged}
      persist={{
        key: TablePersistKey.SERVICE_INVOICE,
        storage: localStorage,
      }}
      rowData={invoices}
      showColumnChooser
      showExport
      showFilterReset
      showUniversalSearch
      rowSelection={rowSelection}
      selectionColumnDef={selectionColumnDef}
      onSelectionChanged={handleSelectionChanged}
      getRowId={getRowId}
    />
  )
}

const useInvoiceColumns = (customerOptions: { label: string; value: string }[]) => {
  const { t } = useTranslation(['invoices', 'translation'])
  const theme = useTheme()

  return [
    {
      field: 'invoiceNumber',
      headerName: t('invoices:table.invoiceNumber'),
      filter: 'agTextColumnFilter',
      cellRenderer: 'agGroupCellRenderer',
    },
    {
      field: 'customer',
      headerName: t('invoices:table.customer'),
      headerTooltip: t('invoices:table.customerTooltip'),
      filter: 'agTextColumnFilter',
      suppressPaste: true,
      editable: (params) => params.data?.uuid.startsWith('temp-') ?? false,
      cellEditor: 'agRichSelectCellEditor',
      cellEditorParams: {
        values: customerOptions.map((option) => option.value),
        formatValue: (value: string) => {
          const option = customerOptions.find((opt) => opt.value === value)
          return option?.label || value
        },
        allowTyping: true,
        filterList: true,
        highlightMatch: true,
        searchType: 'matchAny',
      },
      cellRenderer: ({
        value,
        data,
      }: ICellRendererParams<InvoiceRecord, InvoiceRecord['customer']>) => {
        const formattedValue = value
          ? customerOptions.find((opt) => opt.value === value)?.label || value
          : t('shared.button.edit', { ns: 'translation' })

        return (
          <Space>
            {data?.uuid.startsWith('temp-') && <Pen size={16} />}
            {formattedValue}
          </Space>
        )
      },
      cellStyle: (params) =>
        params.data?.uuid?.startsWith('temp-')
          ? { backgroundColor: `${theme.colors.warning}20` }
          : undefined,
    },
    {
      field: 'paymentStatus',
      headerName: t('invoices:table.paymentStatus'),
      filter: 'agTextColumnFilter',
      editable: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: Object.values(ServiceInvoicePaymentStatus),
      },
      cellRenderer: ({
        value,
      }: ICellRendererParams<InvoiceRecord, InvoiceRecord['paymentStatus']>) => {
        const color =
          value === ServiceInvoicePaymentStatus.PAID
            ? 'success'
            : value === ServiceInvoicePaymentStatus.UNPAID
              ? 'warning'
              : 'default'

        return (
          <Space>
            <Pen size={16} />
            <Tag color={color}>
              {value
                ? t(
                    `invoices:paymentStatus.${value.toLowerCase() as Lowercase<ServiceInvoicePaymentStatus>}`
                  )
                : ''}
            </Tag>
          </Space>
        )
      },
    },
    {
      field: 'type',
      headerName: t('invoices:table.type'),
      headerTooltip: t('invoices:table.typeTooltip'),
      filter: 'agTextColumnFilter',
      editable: (params) => params.data?.uuid.startsWith('temp-') ?? false,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: Object.values(ServiceInvoiceType),
      },
      cellRenderer: ({
        value,
        data,
      }: ICellRendererParams<InvoiceRecord, InvoiceRecord['type']>) => (
        <Space>
          {data?.uuid.startsWith('temp-') && <Pen size={16} />}
          {value ? t(`invoices:type.${value.toLowerCase() as Lowercase<ServiceInvoiceType>}`) : ''}
        </Space>
      ),
      cellStyle: (params) =>
        params.data?.uuid?.startsWith('temp-')
          ? { backgroundColor: `${theme.colors.warning}20` }
          : undefined,
    },
    {
      field: 'issueDate',
      headerName: t('invoices:table.issueDate'),
      headerTooltip: t('invoices:table.issueDateTooltip'),
      filter: 'agDateColumnFilter',
      filterParams: dateFilterParams,
      comparator: dateComparator,
      editable: (params) => params.data?.uuid.startsWith('temp-') ?? false,
      cellEditor: 'agDateStringCellEditor',
      cellEditorParams: {
        min: dayjs().format('YYYY-MM-DD'),
        max: dayjs().add(10, 'years').format('YYYY-MM-DD'),
      },
      valueParser: (params) => {
        if (!params.newValue) return params.oldValue
        return dayjs(params.newValue).format('YYYY-MM-DD')
      },
      cellRenderer: ({ value, data }: ICellRendererParams<InvoiceRecord, string>) => (
        <Space>
          {data?.uuid.startsWith('temp-') && <Pen size={16} />}
          {value
            ? dayjs(value).format('DD.MM.YYYY')
            : t('shared.button.edit', { ns: 'translation' })}
        </Space>
      ),
      cellStyle: (params) =>
        params.data?.uuid?.startsWith('temp-')
          ? { backgroundColor: `${theme.colors.warning}20` }
          : undefined,
    },
    {
      field: 'dueDate',
      headerName: t('invoices:table.dueDate'),
      filter: 'agDateColumnFilter',
      filterParams: dateFilterParams,
      comparator: dateComparator,
      editable: (params) => params.data?.uuid.startsWith('temp-') ?? false,
      cellEditor: 'agDateStringCellEditor',
      cellEditorParams: {
        min: dayjs().format('YYYY-MM-DD'),
        max: dayjs().add(10, 'years').format('YYYY-MM-DD'),
      },
      valueParser: (params) => {
        if (!params.newValue) return params.oldValue
        return dayjs(params.newValue).format('YYYY-MM-DD')
      },
      cellRenderer: ({ value, data }: ICellRendererParams<InvoiceRecord, string>) => (
        <Space>
          {data?.uuid.startsWith('temp-') && <Pen size={16} />}
          {value
            ? dayjs(value).format('DD.MM.YYYY')
            : t('shared.button.edit', { ns: 'translation' })}
        </Space>
      ),
      cellStyle: (params) =>
        params.data?.uuid?.startsWith('temp-')
          ? { backgroundColor: `${theme.colors.warning}20` }
          : undefined,
    },
    {
      field: 'sentAt',
      headerName: t('invoices:table.sentAt'),
      filter: 'agDateColumnFilter',
      filterParams: dateFilterParams,
      comparator: dateComparator,
      cellRenderer: ({ value }: ICellRendererParams<InvoiceRecord, string>) => (
        <FormattedDate date={value} withRelativeTime />
      ),
    },
    {
      field: 'updatedAt',
      headerName: t('invoices:table.updatedAt'),
      filter: 'agDateColumnFilter',
      filterParams: dateFilterParams,
      comparator: dateComparator,
      hide: true,
      cellRenderer: ({ value }: ICellRendererParams<InvoiceRecord, string>) => (
        <FormattedDate date={value} withRelativeTime />
      ),
    },
    {
      field: 'createdAt',
      headerName: t('invoices:table.createdAt'),
      headerTooltip: t('invoices:table.createdAtTooltip'),
      filter: 'agDateColumnFilter',
      filterParams: dateFilterParams,
      comparator: dateComparator,
      hide: true,
      sort: 'desc',
      cellRenderer: ({ value }: ICellRendererParams<InvoiceRecord, string>) => (
        <FormattedDate date={value} withRelativeTime />
      ),
    },
    {
      field: 'uuid',
      headerName: t('invoices:table.uuid'),
      filter: 'agTextColumnFilter',
      hide: true,
    },
  ] as ColDef<InvoiceRecord>[]
}
