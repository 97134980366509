import dayjs from 'dayjs'
import { GetDashboardPerformanceOverviewQuery } from '../../../../generated/graphql'
import { DashboardOrdersListItem } from '../components/DashboardOrdersList'

export function prepareLatestOrders(
  data: GetDashboardPerformanceOverviewQuery | undefined
): DashboardOrdersListItem[] {
  if (!data) {
    return []
  }

  const orders = data.products.flatMap((product) => {
    if (!product.productVariants) {
      return []
    }

    return product.productVariants.flatMap((productVariant) => {
      if (!productVariant.amazonProducts) {
        return []
      }

      return productVariant.amazonProducts.flatMap((amazonProduct) => {
        if (!amazonProduct.amazonListings) {
          return []
        }

        return amazonProduct.amazonListings.flatMap((amazonListing) => {
          return amazonListing.orderLineItems.map((item) => {
            const order = item.amazonOrder

            return {
              internalSku: productVariant.internalSku ?? null,
              recommendedRetailPrice: amazonListing.recommendedRetailPrice ?? 0,
              marketplace: amazonListing.marketplace.name,
              asin: amazonProduct.asin,
              sku: amazonListing.sku,
              quantity: item.quantity,
              price: {
                amount: +(item.itemPrice.amount ?? 0),
                currency: 'EUR',
              },
              status: order.status,
              fulfillmentChannel: order.fulfillmentChannel,
              purchaseDate: order.purchaseDate,
            }
          })
        })
      })
    })
  })

  const sortedOrders = orders.sort((a, b) => {
    return dayjs(b.purchaseDate).diff(dayjs(a.purchaseDate))
  })

  return sortedOrders
}
