import { useMutation, useQuery } from '@apollo/client'
import { Button, DatePicker, Space } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { BanknoteIcon, Send } from 'lucide-react'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'sonner'
import { ViewContainer } from '../../../components/Layout/ContentWrapper'
import { StatusResult } from '../../../components/Layout/StatusResult/StatusResult'
import { SubHeader } from '../../../components/Layout/SubHeader/SubHeader'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import {
  CancelServiceInvoicesDocument,
  GetServiceInvoicesDocument,
  GetServiceInvoicesQuery,
  SendServiceInvoicesDocument,
  ServiceInvoicePaymentStatus,
} from '../../../generated/graphql'
import { useDateRangePresets } from '../../../hooks/useDateRangePresets'
import { useGlobalStore } from '../../../stores/useGlobalStore'
import { InvoiceRecord, InvoiceTable } from './InvoiceTable'

const { RangePicker } = DatePicker

const defaultDateRange: [Dayjs, Dayjs] = [dayjs().startOf('year'), dayjs().endOf('year')]

export const InvoiceView = () => {
  const selectedCompany = useGlobalStore((state) => state.selectedCompany)!
  const { t } = useTranslation(['invoices', 'translation'])
  const [selectedInvoices, setSelectedInvoices] = useState<InvoiceRecord[]>([])
  const [dateRange, setDateRange] = useState(defaultDateRange)

  const presets = useDateRangePresets()

  const { error, data, loading } = useQuery(GetServiceInvoicesDocument, {
    skip: !selectedCompany.uuid,
    variables: {
      companyUuid: selectedCompany.uuid,
      from: dateRange[0].format('YYYY-MM-DD'),
      to: dateRange[1].format('YYYY-MM-DD'),
    },
    fetchPolicy: 'cache-first',
  })

  const [sendInvoices, { loading: sendingInvoices }] = useMutation(SendServiceInvoicesDocument)
  const [cancelInvoices, { loading: cancelingInvoices }] = useMutation(
    CancelServiceInvoicesDocument
  )

  const handleSendInvoices = useCallback(async () => {
    if (selectedInvoices.length === 0) return

    try {
      const { data } = await sendInvoices({
        variables: {
          input: {
            serviceInvoiceUuids: selectedInvoices.map((invoice) => invoice.uuid),
          },
        },
        update: (cache, { data: mutationData }) => {
          const existingData = cache.readQuery<GetServiceInvoicesQuery>({
            query: GetServiceInvoicesDocument,
            variables: { companyUuid: selectedCompany.uuid },
          })

          if (!existingData || !mutationData?.sendServiceInvoices.results) return

          const updatedInvoices = existingData.serviceInvoices.map((invoice) => {
            const sendResult = mutationData.sendServiceInvoices.results.find(
              (result) => result.invoiceUuid === invoice.uuid
            )
            if (sendResult?.success) {
              return {
                ...invoice,
                sentAt: new Date().toISOString(),
              }
            }
            return invoice
          })

          cache.writeQuery({
            query: GetServiceInvoicesDocument,
            variables: {
              companyUuid: selectedCompany.uuid,
              from: dateRange[0].format('YYYY-MM-DD'),
              to: dateRange[1].format('YYYY-MM-DD'),
            },
            data: {
              serviceInvoices: updatedInvoices,
            },
          })
        },
      })

      const failedInvoices = data?.sendServiceInvoices.results.filter((result) => !result.success)

      if (failedInvoices && failedInvoices.length > 0) {
        failedInvoices.forEach((result) => {
          toast.error(result.message)
        })
      } else {
        toast.success(t('invoices:send.success'))
      }

      setSelectedInvoices([])
    } catch (error) {
      console.error(error)
      toast.error(t('invoices:send.error'))
    }
  }, [sendInvoices, selectedInvoices, t])

  const handleCancelInvoices = useCallback(async () => {
    if (selectedInvoices.length === 0) return

    try {
      const { data } = await cancelInvoices({
        variables: {
          input: {
            serviceInvoiceUuids: selectedInvoices.map((invoice) => invoice.uuid),
          },
        },
        update: (cache, { data: mutationData }) => {
          const existingData = cache.readQuery<GetServiceInvoicesQuery>({
            query: GetServiceInvoicesDocument,
            variables: {
              companyUuid: selectedCompany.uuid,
              from: dateRange[0].format('YYYY-MM-DD'),
              to: dateRange[1].format('YYYY-MM-DD'),
            },
          })

          if (!existingData || !mutationData?.cancelServiceInvoices.results) return

          const updatedInvoices = existingData.serviceInvoices.map((invoice) => {
            const cancelResult = mutationData.cancelServiceInvoices.results.find(
              (result) => result.invoiceUuid === invoice.uuid
            )
            if (cancelResult?.success) {
              return {
                ...invoice,
                paymentStatus: ServiceInvoicePaymentStatus.CANCELED,
              }
            }
            return invoice
          })

          cache.writeQuery({
            query: GetServiceInvoicesDocument,
            variables: {
              companyUuid: selectedCompany.uuid,
              from: dateRange[0].format('YYYY-MM-DD'),
              to: dateRange[1].format('YYYY-MM-DD'),
            },
            data: {
              serviceInvoices: updatedInvoices,
            },
          })
        },
      })

      const failedInvoices = data?.cancelServiceInvoices.results.filter((result) => !result.success)

      if (failedInvoices && failedInvoices.length > 0) {
        failedInvoices.forEach((result) => {
          toast.error(result.message)
        })
      } else {
        toast.success(t('invoices:cancel.success'))
      }

      setSelectedInvoices([])
    } catch (error) {
      console.error(error)
      toast.error(t('invoices:cancel.error'))
    }
  }, [cancelInvoices, selectedInvoices, selectedCompany.uuid, dateRange, t])

  if (loading) {
    return <LoadingSpinner />
  }

  if (error) {
    return (
      <StatusResult status="500" title="500" subTitle={t('translation:shared.error.message')} />
    )
  }

  const invoices = prepareInvoices(data)

  return (
    <>
      <SubHeader
        heading={t('invoices:heading')}
        rightContent={
          <Space>
            <RangePicker
              value={dateRange}
              onCalendarChange={(values) => {
                if (values?.[0] && values?.[1]) {
                  setDateRange([values[0].startOf('day'), values[1].endOf('day')])
                }
              }}
              allowClear={false}
              disabledDate={(date) => date.isAfter(dayjs())}
              format="DD.MM.YYYY"
              placement="bottomRight"
              presets={presets}
            />
            <Button
              type="primary"
              icon={<Send size={16} />}
              onClick={handleSendInvoices}
              disabled={selectedInvoices.length === 0}
              loading={sendingInvoices}
            >
              {t('invoices:send.button')}
            </Button>
            <Button
              danger
              type="primary"
              icon={<BanknoteIcon size={16} />}
              onClick={handleCancelInvoices}
              disabled={selectedInvoices.length === 0}
              loading={cancelingInvoices}
            >
              {t('invoices:cancel.button')}
            </Button>
          </Space>
        }
      />
      <ViewContainer>
        <InvoiceTable invoices={invoices} onSelectionChange={setSelectedInvoices} />
      </ViewContainer>
    </>
  )
}

export const prepareInvoices = (data: GetServiceInvoicesQuery | undefined): InvoiceRecord[] => {
  if (!data) {
    return []
  }

  return data.serviceInvoices.map((invoice) => ({
    uuid: invoice.uuid,
    invoiceNumber: invoice.invoiceNumber,
    customer: invoice.debtorCompany.sellerId,
    paymentStatus: invoice.paymentStatus,
    type: invoice.type,
    issueDate: invoice.issueDate,
    dueDate: invoice.dueDate ?? null,
    updatedAt: invoice.updatedAt ?? null,
    createdAt: invoice.createdAt ?? null,
    sentAt: invoice.sentAt ?? null,
    bucketKey: invoice.bucketKey ?? null,
    lineItems:
      invoice.lineItems?.map((item) => ({
        uuid: item.uuid,
        title: item.title,
        netAmount: item.netAmount,
        currency: item.currency,
        commissionInPercent: item.commissionInPercent,
        quantity: item.quantity,
        taxRate: item.taxRate,
        bookingNumber: item.bookingNumber,
        paymentStatus: item.paymentStatus,
        issueDate: item.issueDate,
        performancePeriodStartDate: item.performancePeriodStartDate,
        performancePeriodEndDate: item.performancePeriodEndDate,
        createdAt: item.createdAt,
        updatedAt: item.updatedAt,
      })) ?? [],
  }))
}
