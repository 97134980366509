import dayjs, { Dayjs } from 'dayjs'
import {
  AmazonOrderStatus,
  GetDashboardAmazonAdsPerformanceQuery,
} from '../../../../../generated/graphql'

type AmazonAdsMetricKey = 'impressions' | 'clicks' | 'spend' | 'adSales'

type AccumulatedAmazonAdsMetrics = Record<
  AmazonAdsMetricKey,
  { total: number; previousTotal: number; values: { date: string; value: number }[] }
>

const initialValues = {
  impressions: {
    total: 0,
    previousTotal: 0,
    values: [],
  },
  clicks: {
    total: 0,
    previousTotal: 0,
    values: [],
  },
  spend: {
    total: 0,
    previousTotal: 0,
    values: [],
  },
  // adOrders: {
  //   total: 0,
  //   previousTotal: 0,
  //   values: [],
  // },
  adSales: {
    total: 0,
    previousTotal: 0,
    values: [],
  },
  acos: {
    total: 0,
    previousTotal: 0,
    values: [],
  },
  // totalOrders: {
  //   total: 0,
  //   previousTotal: 0,
  //   values: [],
  // },
  totalSales: {
    total: 0,
    previousTotal: 0,
    values: [],
  },
  tacos: {
    total: 0,
    previousTotal: 0,
    values: [],
  },
}

export function prepareAmazonAdsMetrics(
  data: GetDashboardAmazonAdsPerformanceQuery | undefined,
  startDate: Dayjs,
  endDate: Dayjs,
  startDatePreviousPeriod: Dayjs,
  endDatePreviousPeriod: Dayjs
) {
  if (!data) {
    return initialValues
  }

  const dateList = []
  let currentDate = startDate
  while (currentDate.isBefore(endDate) || currentDate.isSame(endDate)) {
    dateList.push(currentDate.format('YYYY-MM-DD'))
    currentDate = currentDate.add(1, 'day')
  }

  const metrics = data.amazonAdsCampaigns?.reduce<AccumulatedAmazonAdsMetrics>(
    (accumulator, currentValue) => {
      if (!currentValue.metrics) {
        return accumulator
      }

      for (const metric of currentValue.metrics) {
        if (dayjs(metric.date).isBetween(startDate, endDate, 'd', '[]')) {
          const impressionsIndex = accumulator.impressions.values.findIndex(
            (value) => value.date === metric.date
          )

          if (impressionsIndex !== -1) {
            accumulator.impressions.values[impressionsIndex].value += metric.impressions
          } else {
            accumulator.impressions.values.push({
              date: metric.date,
              value: metric.impressions,
            })
          }

          const clicksIndex = accumulator.clicks.values.findIndex(
            (value) => value.date === metric.date
          )

          if (clicksIndex !== -1) {
            accumulator.clicks.values[clicksIndex].value += metric.clicks
          } else {
            accumulator.clicks.values.push({
              date: metric.date,
              value: metric.clicks,
            })
          }

          const spendIndex = accumulator.spend.values.findIndex(
            (value) => value.date === metric.date
          )

          if (spendIndex !== -1) {
            accumulator.spend.values[spendIndex].value += metric.cost
          } else {
            accumulator.spend.values.push({
              date: metric.date,
              value: metric.cost,
            })
          }

          const adSalesIndex = accumulator.adSales.values.findIndex(
            (value) => value.date === metric.date
          )

          if (adSalesIndex !== -1) {
            accumulator.adSales.values[adSalesIndex].value += metric.sales
          } else {
            accumulator.adSales.values.push({
              date: metric.date,
              value: metric.sales,
            })
          }

          accumulator.impressions.total += metric.impressions
          accumulator.clicks.total += metric.clicks
          accumulator.spend.total += metric.cost
          accumulator.adSales.total += metric.sales
        } else if (
          dayjs(metric.date).isBetween(startDatePreviousPeriod, endDatePreviousPeriod, 'd', '[]')
        ) {
          accumulator.impressions.previousTotal += metric.impressions
          accumulator.clicks.previousTotal += metric.clicks
          accumulator.spend.previousTotal += metric.cost
          accumulator.adSales.previousTotal += metric.sales
        }
      }

      return accumulator
    },
    {
      impressions: {
        total: 0,
        previousTotal: 0,
        values: [],
      },
      clicks: {
        total: 0,
        previousTotal: 0,
        values: [],
      },
      spend: {
        total: 0,
        previousTotal: 0,
        values: [],
      },
      adSales: {
        total: 0,
        previousTotal: 0,
        values: [],
      },
    }
  )

  const orders = data.products.flatMap((product) => {
    const amazonProducts =
      product.productVariants?.flatMap((productVariant) => {
        return productVariant.amazonProducts ?? []
      }) ?? []

    return amazonProducts.flatMap((amazonProduct) => {
      if (!amazonProduct.amazonListings) {
        return []
      }

      return amazonProduct.amazonListings.flatMap((amazonListing) => {
        return amazonListing.orderLineItems.map((item) => {
          const order = item.amazonOrder
          return {
            purchaseDate: dayjs(order.purchaseDate).format('YYYY-MM-DD'),
            price: {
              amount: +(item.itemPrice.amount ?? 0),
              currency: 'EUR',
            },
            status: order.status,
          }
        })
      })
    })
  })

  const { totalSales } = orders.reduce<{
    totalSales: { total: number; previousTotal: number; values: { date: string; value: number }[] }
  }>(
    (accumulator, currentValue) => {
      if (currentValue.status !== AmazonOrderStatus.SHIPPED) {
        return accumulator
      }

      if (dayjs(currentValue.purchaseDate).isBetween(startDate, endDate, 'd', '[]')) {
        const totalSalesIndex = accumulator.totalSales.values.findIndex(
          (value) => value.date === currentValue.purchaseDate
        )

        if (totalSalesIndex !== -1) {
          accumulator.totalSales.values[totalSalesIndex].value += currentValue.price.amount!
        } else {
          accumulator.totalSales.values.push({
            date: currentValue.purchaseDate,
            value: currentValue.price.amount!,
          })
        }

        accumulator.totalSales.total += currentValue.price.amount!
      } else if (
        dayjs(currentValue.purchaseDate).isBetween(
          startDatePreviousPeriod,
          endDatePreviousPeriod,
          'd',
          '[]'
        )
      ) {
        accumulator.totalSales.previousTotal += currentValue.price.amount!
      }

      return accumulator
    },
    {
      totalSales: {
        total: 0,
        previousTotal: 0,
        values: [],
      },
    }
  )

  const { acos, tacos } = metrics?.spend.values.reduce<{
    acos: { total: number; previousTotal: number; values: { date: string; value: number }[] }
    tacos: { total: number; previousTotal: number; values: { date: string; value: number }[] }
  }>(
    (accumulator, currentValue) => {
      const adSalesIndex = metrics.adSales.values.findIndex(
        (value) => value.date === currentValue.date
      )

      if (adSalesIndex !== -1) {
        accumulator.acos.values.push({
          date: currentValue.date,
          value: metrics.adSales.values[adSalesIndex].value
            ? currentValue.value / (metrics.adSales.values[adSalesIndex].value || 1)
            : 0,
        })
      }

      const totalSalesIndex = totalSales.values.findIndex(
        (value) => value.date === currentValue.date
      )

      if (totalSalesIndex !== -1) {
        accumulator.tacos.values.push({
          date: currentValue.date,
          value: currentValue.value / (totalSales.values[totalSalesIndex].value || 1),
        })
      }

      return accumulator
    },
    {
      acos: {
        total:
          metrics?.spend.total && metrics?.adSales.total
            ? metrics?.spend.total / metrics?.adSales.total
            : 0,
        previousTotal:
          metrics?.spend.previousTotal && metrics?.adSales.previousTotal
            ? metrics?.spend.previousTotal / metrics?.adSales.previousTotal
            : 0,
        values: [],
      },
      tacos: {
        total:
          metrics?.spend.total && totalSales.total ? metrics?.spend.total / totalSales.total : 0,
        previousTotal:
          metrics?.spend.previousTotal && totalSales.previousTotal
            ? metrics?.spend.previousTotal / totalSales.previousTotal
            : 0,
        values: [],
      },
    }
  ) ?? {
    acos: {
      total: 0,
      previousTotal: 0,
      values: [],
    },
    tacos: {
      total: 0,
      previousTotal: 0,
      values: [],
    },
  }

  if (metrics)
    for (const date of dateList) {
      for (const metricKey of Object.keys(metrics) as AmazonAdsMetricKey[]) {
        if (!metrics[metricKey].values.find((value) => value.date === date)) {
          metrics[metricKey].values.push({
            date: date,
            value: 0,
          })
        }
      }

      if (!acos.values.find((value) => value.date === date)) {
        acos.values.push({
          date: date,
          value: 0,
        })
      }
      if (!totalSales.values.find((value) => value.date === date)) {
        totalSales.values.push({
          date: date,
          value: 0,
        })
      }
      if (!tacos.values.find((value) => value.date === date)) {
        tacos.values.push({
          date: date,
          value: 0,
        })
      }
      // TODO: add adOrders, totalOrders once they are uncommented above
    }

  metrics?.impressions.values.sort((a, b) => {
    return dayjs(a.date).isAfter(b.date) ? 1 : -1
  })

  metrics?.clicks.values.sort((a, b) => {
    return dayjs(a.date).isAfter(b.date) ? 1 : -1
  })

  metrics?.spend.values.sort((a, b) => {
    return dayjs(a.date).isAfter(b.date) ? 1 : -1
  })

  metrics?.adSales.values.sort((a, b) => {
    return dayjs(a.date).isAfter(b.date) ? 1 : -1
  })

  acos.values.sort((a, b) => {
    return dayjs(a.date).isAfter(b.date) ? 1 : -1
  })

  totalSales.values.sort((a, b) => {
    return dayjs(a.date).isAfter(b.date) ? 1 : -1
  })

  tacos?.values.sort((a, b) => {
    return dayjs(a.date).isAfter(b.date) ? 1 : -1
  })

  return {
    impressions: metrics?.impressions,
    clicks: metrics?.clicks,
    spend: metrics?.spend,
    // adOrders,
    adSales: metrics?.adSales,
    acos,
    // totalOrders,
    totalSales,
    tacos,
  }
}
